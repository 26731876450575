import { FC, Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { classNames } from '../../libs/classNames'
import { useAuthenticator } from '@aws-amplify/ui-react'
import Link from 'next/link'
import { GravatarProfileImage } from '../Gravatar/ProfileImage'
import { getUsername } from '../../libs/AWS/amplify/user.utils'

export const LargeDisplayDropdownMenu: FC<{ open: boolean }> = ({ open }) => {
  const { user, signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ])
  const username = getUsername(user)
  return (
    <>
      <div className='flex items-center lg:hidden'>
        {/* Mobile menu button */}
        <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
          <span className='sr-only'>Open main menu</span>
          {open ? (
            <XIcon className='block h-6 w-6' aria-hidden='true' />
          ) : (
            <MenuIcon className='block h-6 w-6' aria-hidden='true' />
          )}
        </Disclosure.Button>
      </div>
      <div className='hidden lg:ml-4 lg:flex lg:items-center'>
        {/* Profile dropdown */}
        {authStatus === 'authenticated' ? (
          <Menu as='div' className='ml-4 relative flex-shrink-0'>
            <div>
              <Menu.Button className='bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                <span className='sr-only'>Open user menu</span>
                <GravatarProfileImage
                  className='h-8 w-8 rounded-full'
                  email={user.attributes?.email}
                  size={48}
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                <Menu.Item>
                  <div className='px-4 py-3'>
                    <div className='text-sm font-medium text-gray-800'>{username}</div>
                    <div className='text-xs font-medium text-gray-500'>
                      {user.attributes?.email}
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link href='/mypage/home/' passHref>
                      <a
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        My page
                      </a>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'w-full text-left block px-4 py-2 text-sm text-gray-700',
                      )}
                      onClick={() => signOut()}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <>
            <Link href='/mypage/' passHref>
              <a className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700'>
                Sign in / Sign up
              </a>
            </Link>
          </>
        )}
      </div>
    </>
  )
}
