import { useRouter } from 'next/router'
import Script from 'next/script'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'

export type PutGAEvent = (eventName: Gtag.EventNames, params: Gtag.EventParams) => void
export type PutGAPageviewEvent = (params: Gtag.ConfigParams) => void
export type ConfigureGA = (params: Gtag.ConfigParams) => void
export type UpdateGAConsent = (params: Gtag.ConsentParams) => void
export type SetGAAttributes = (params: Gtag.CustomParams) => void

const GoogleAnalyticsContext = createContext<{
  putEvent: PutGAEvent
  putPageviewEvent: PutGAPageviewEvent
  configure: ConfigureGA
  updateConsent: UpdateGAConsent
  setAttributes: SetGAAttributes
}>({} as any)
export const useGoogleAnalytics = () => useContext(GoogleAnalyticsContext)

export const GoogleAnalyticsProvider: FC<
  PropsWithChildren<{
    gaTrackingId?: string
    defaultConsents?: Gtag.ConsentParams[]
    debugMode?: boolean
  }>
> = ({ children, gaTrackingId, defaultConsents, debugMode = false }) => {
  const router = useRouter()
  const { events } = router || {}

  const putEvent: PutGAEvent = useCallback((eventName, params) => {
    if (!window) return
    window.gtag('event', eventName, params)
  }, [])
  const configure: ConfigureGA = useCallback(
    (params) => {
      if (!gaTrackingId) return
      if (!window) return
      window.gtag('config' as any, gaTrackingId, params)
    },
    [gaTrackingId],
  )
  const putPageviewEvent: PutGAPageviewEvent = useCallback(
    (params) => {
      configure(params)
    },
    [configure],
  )
  const consent = useCallback((type: Gtag.ConsentArg, params: Gtag.ConsentParams) => {
    if (!window) return
    window.gtag('consent', type, params)
  }, [])
  const updateConsent: UpdateGAConsent = useCallback(
    (params) => {
      if (!window) return
      consent('update', params)
    },
    [consent],
  )
  const setAttributes: SetGAAttributes = useCallback((params) => {
    if (!window) return
    window.gtag('set', params)
  }, [])

  const initGA = useCallback(
    (config?: Omit<Gtag.ConfigParams, 'page_path'>) => {
      gtag('js', new Date())
      if (defaultConsents)
        defaultConsents.forEach((defaultConsent) => consent('default', defaultConsent))
      configure({
        ...config,
        debug_mode: debugMode,
        page_path: window.location.pathname,
      } as any)
    },
    [configure, defaultConsents, debugMode, consent],
  )

  const didLoadRef = useRef(false)

  /**
   * Tracking pageview
   */
  useEffect(() => {
    if (!didLoadRef.current) {
      didLoadRef.current = true
      initGA()
    }
    const handleRouteChange = (url: string) => {
      if (!window) return
      configure({
        page_location: url,
      })
    }
    events.on('routeChangeComplete', handleRouteChange)
    events.on('hashChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
      events.off('hashChangeComplete', handleRouteChange)
    }
  }, [configure, initGA, events])

  return (
    <GoogleAnalyticsContext.Provider
      value={{
        putEvent,
        putPageviewEvent,
        configure,
        updateConsent,
        setAttributes,
      }}
    >
      {gaTrackingId ? (
        <>
          <Script
            async
            strategy='lazyOnload'
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
          />
          <Script strategy='afterInteractive' id='ga'>
            {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){
                        dataLayer.push(arguments);
                    }
                  `}
          </Script>
        </>
      ) : null}
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}
