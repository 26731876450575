import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { callAPIWithAuth } from '../../libs/AWS/amplify/user.utils'
import { canUseBookmarkFeature } from '../../libs/bookmarks/permissions'
import { usePrivatePage } from '../Mypages/Provider'
import { useToast } from '../Toast/Provider'

export type Bookmark = {
  username: string
  post_id: number
  bookmarked_at: number
}

const BookmarkContext = createContext<{
  putBookmark: (postId: number, callback?: () => void) => Promise<void> | void
  listBookmark: () => Promise<void> | void
  removeBookmark: (postId: number, callback?: () => void) => Promise<void> | void
  bookmarks: Bookmark[] | undefined
  canUseBookmark: boolean | undefined
}>({} as any)

export const useBookmark = () => useContext(BookmarkContext)

export const BookmarkProviderContent: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { putToastMessages } = useToast()
  const { userGroupNames } = usePrivatePage()
  const canUseBookmark = useMemo(() => {
    if (!userGroupNames) return undefined
    return canUseBookmarkFeature(userGroupNames)
  }, [userGroupNames])
  const [bookmarks, setBookmarks] = useState<Bookmark[] | undefined>(undefined)

  const listBookmark = useCallback(() => {
    if (!canUseBookmark) {
      setBookmarks([])
      return
    }
    callAPIWithAuth('/api/bookmarks', {
      method: 'GET',
    })
      .then((data) => data.json())
      .then(async (bookmarks) => {
        setBookmarks(bookmarks)
      })
      .catch((e) => {
        console.log(e)
        setBookmarks([])
      })
  }, [setBookmarks, canUseBookmark])
  const putBookmark = useCallback(
    (postId: number, callback?: () => void) => {
      callAPIWithAuth('/api/bookmarks', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          post_id: postId,
        }),
      })
        .then(() => {
          listBookmark()
          callback?.()
          putToastMessages({
            type: 'success',
            title: 'Bookmarked!',
          })
        })
        .catch((e) => {
          console.log(e)
          window.alert((e as Error).message)
        })
    },
    [listBookmark, putToastMessages],
  )
  const removeBookmark = useCallback(
    (postId: number, callback?: () => void) => {
      callAPIWithAuth('/api/bookmarks', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          post_id: postId,
        }),
      })
        .then(() => {
          listBookmark()
          callback?.()
          putToastMessages({
            type: 'success',
            title: 'Bookmark has removed',
          })
        })
        .catch((e) => {
          console.log(e)
          window.alert((e as Error).message)
        })
    },
    [listBookmark, putToastMessages],
  )

  const didLoadRef = useRef(false)
  useEffect(() => {
    if (!canUseBookmark) return
    if (didLoadRef.current) return
    didLoadRef.current = true
    listBookmark()
  }, [canUseBookmark, listBookmark])

  return (
    <BookmarkContext.Provider
      value={{
        listBookmark,
        bookmarks,
        canUseBookmark,
        putBookmark,
        removeBookmark,
      }}
    >
      {children}
    </BookmarkContext.Provider>
  )
}

export const BookmarkProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user])
  return <BookmarkProviderContent key={user?.username || ''}>{children}</BookmarkProviderContent>
}
