import React, { FC, Fragment, useCallback, useMemo } from 'react'
import { CookieConsent } from 'react-cookie-consent'
import cookie from 'cookie'
import { useRouter } from 'next/router'
import { useGoogleAnalytics } from '../Analytics/GoogleAnalytics'

export function getCookieByName(name = 'CookieConsent') {
  if (!Object.prototype.hasOwnProperty.call(process, 'browser')) return false
  if (!window || !window.document) return false
  const cookies = cookie.parse(window.document.cookie)
  return cookies[name]
}

export const isCookieConsent = () => {
  const value = getCookieByName()
  return value === 'true'
}

export const useCookieConsentBannerHook = (lang = 'en') => {
  const { updateConsent } = useGoogleAnalytics()
  const accept = useCallback(() => {
    updateConsent({
      analytics_storage: 'granted',
      ad_storage: 'granted',
    })
  }, [updateConsent])
  const decline = useCallback(() => {
    if (lang !== 'en') {
      location.href = 'https://yahoo.co.jp'
    }
    updateConsent({
      analytics_storage: 'denied',
      ad_storage: 'denied',
    })
  }, [lang, updateConsent])
  const texts = useMemo(() => {
    if (lang === 'en') return ['This website uses cookies to enhance the user experience.']
    return [
      'このサイトでは、コンテンツの表示・アクセスログの解析・広告配信等を目的にJavaScriptを利用しています。',
      'スクロールまたは同意ボタンのクリックを持って利用に合意したものとします。',
    ]
  }, [lang])
  const consent = getCookieByName()
  /**
   * 日本人向けは許可しないとずっと出す
   */
  const sholdShowForJP = useMemo(() => {
    if (lang === 'en') return false
    return consent !== 'true'
  }, [consent, lang])
  return {
    accept,
    decline,
    texts,
    consent,
    sholdShowForJP,
  }
}

export const CookieConsentBanner: FC = () => {
  const { locale } = useRouter()
  const lang = locale || 'en'
  const { accept, decline, texts, consent, sholdShowForJP } = useCookieConsentBannerHook(lang)
  if (lang === 'en' && consent !== undefined) return null
  return (
    <CookieConsent
      containerClasses='z-50'
      enableDeclineButton
      onDecline={decline}
      onAccept={accept}
      debug={sholdShowForJP}
      buttonText={lang === 'en' ? 'Accept' : '同意する'}
      declineButtonText={lang === 'en' ? 'Decline' : '合意しない（Yahoo Japanへ）'}
    >
      <small>
        {texts.map((text, i) => (
          <Fragment key={i}>
            {text}
            <br />
          </Fragment>
        ))}
      </small>
    </CookieConsent>
  )
}
