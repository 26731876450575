import { useAuthenticator } from '@aws-amplify/ui-react'
import { FC } from 'react'
import { getUsername, callAPIWithAuth } from '../../../libs/AWS/amplify/user.utils'
import { GravatarProfileImage } from '../../Gravatar/ProfileImage'
import { BillingPortalButton } from './BillingPortalButton'

export const MypageHeader: FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const username = getUsername(user)
  return (
    <div className='max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8'>
      <div className='flex items-center space-x-5'>
        <div className='flex-shrink-0'>
          <div className='relative'>
            <GravatarProfileImage
              className='h-16 w-16 rounded-full'
              email={user.attributes?.email}
              size={48}
            />
            <span className='absolute inset-0 shadow-inner rounded-full' aria-hidden='true' />
          </div>
        </div>
        <div>
          <h1 className='text-2xl font-bold text-gray-900'>{username}</h1>
          <p className='text-sm font-medium text-gray-500'>{user.attributes?.email}</p>
        </div>
      </div>
      <div className='mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3'>
        <button
          type='button'
          onClick={signOut}
          className='inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500'
        >
          Sign out
        </button>
        <BillingPortalButton className='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500' />
      </div>
    </div>
  )
}
