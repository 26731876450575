import { HomeIcon } from '@heroicons/react/outline'
import { TranslateIcon } from '@heroicons/react/solid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { classNames } from '../../libs/classNames'
import { menus } from '../../libs/navigations/menus'
import { getWPLang } from '../../libs/wp/fetchers'

export const MenuWidget: FC = () => {
  const router = useRouter()
  const { locale, asPath } = router || {}
  return (
    <div className='pb-8 space-y-1'>
      {menus.sidebar.map((item) => {
        const current = asPath === `${item.href}/`.replace(/\/\//, '/')
        return (
          <Link key={`menu-item-${item.name}`} href={item.href} passHref>
            <a
              className={classNames(
                current ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
              )}
              aria-current={current ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                )}
                aria-hidden='true'
              />
              <span className='truncate'>{item.name}</span>
            </a>
          </Link>
        )
      })}
      {asPath ? (
        <Link
          key={`menu-item-lang`}
          href={asPath}
          locale={getWPLang(locale) === 'ja' ? 'en' : 'ja'}
          passHref
        >
          <a
            className={
              'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md'
            }
          >
            <TranslateIcon
              className={'text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6'}
              aria-hidden='true'
            />
            <span className='truncate'>{getWPLang(locale) === 'ja' ? 'English' : '日本語'}</span>
          </a>
        </Link>
      ) : null}
    </div>
  )
}
