import { FC, PropsWithChildren } from 'react'
import { callAPIWithAuth } from '../../../libs/AWS/amplify/user.utils'
import { usePrivatePage } from '../Provider'

export const BillingPortalButton: FC<
  PropsWithChildren<{
    className?: string
  }>
> = ({ className, children = 'Open Billing Portal' }) => {
  const { stripeCustomerId } = usePrivatePage()
  if (!stripeCustomerId) {
    return null
  }
  return (
    <button
      type='button'
      className={className}
      onClick={async () => {
        const result = await callAPIWithAuth('/api/users/billing_portal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((data) => data.json())
        if (result.message) {
          window.alert(result.message)
        }
        if (result.url) {
          window.open(result.url, 'WPKyotoCustomerPortal')
        }
      }}
    >
      {children}
    </button>
  )
}
