import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useMemo } from 'react'
import { ArchiveLayout } from './Archive'
import { HomeLayout } from './Home'
import { PostLayout } from './Post'
import { PrivatePageLayout } from './Private'

export const AppLayout: FC<PropsWithChildren<any>> = ({ children, ...appProps }) => {
  const { pathname } = useRouter()
  const pageType = useMemo(() => {
    if (/^\/mypage/.test(pathname)) return 'mypage'
    switch (pathname) {
      case '':
      case '/':
      case '/recent-visited':
        return 'home'
      case '/search':
        return 'search'
      case '/pages/[page]':
      case '/licenses':
      case '/category/[category]':
      case '/category/[category]/pages/[page]':
        return 'archive'
      case '/[slug]':
        return 'post'
      case '/bookmarks':
        return 'archive'
        return 'mypage'
      default:
        return ''
    }
  }, [pathname])

  const props = { children, ...appProps }
  switch (pageType) {
    case 'archive': {
      return <ArchiveLayout {...props} />
    }
    case 'home':
      return <HomeLayout {...props} />
    case 'post': {
      return <PostLayout {...props} />
    }
    case 'mypage':
      return <PrivatePageLayout {...props} />
    case 'search':
    default:
      return <>{children}</>
  }
}
