import { FC } from 'react'
import { Disclosure } from '@headlessui/react'
import { HeaderSearchForm } from './SearchForm'
import { GlobalNavigation } from './GlobalNavigation'
import { MobileNavigation } from './MobileNavigation'
import { LargeDisplayDropdownMenu } from './LargeDisplayDropdownMenu'

export const Header: FC = () => {
  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8'>
            <div className='flex justify-between h-16'>
              <GlobalNavigation siteName='WP-Kyoto' siteLogoURL='/images/icon-384.png' />
              <HeaderSearchForm />
              <LargeDisplayDropdownMenu open={open} />
            </div>
          </div>

          <MobileNavigation />
        </>
      )}
    </Disclosure>
  )
}
