import { SearchIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

export const HeaderSearchForm: FC = () => {
  const { push, pathname } = useRouter()
  const [word, setWord] = useState('')
  const goToSearchPage = async () => {
    if (pathname !== '/search') {
      await push(`/search?query=${word}`)
    }
  }

  if (pathname === '/search') return null

  return (
    <div className='flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end'>
      <div className='max-w-lg w-full'>
        <label htmlFor='search' className='sr-only'>
          Search
        </label>
        <form
          className='relative mt-1 flex rounded-md shadow-sm'
          onSubmit={(e) => {
            e.preventDefault()
            goToSearchPage()
          }}
        >
          <div className='relative flex items-stretch flex-grow focus-within:z-10'>
            <input
              type='search'
              name='search'
              id='search'
              className='focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-5 sm:text-sm border-gray-300'
              placeholder='Search'
              onChange={({ target }) => setWord(target.value)}
              value={word}
            />
          </div>
          <button
            type='submit'
            className='-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
          >
            <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            <span className='hidden md:inline'>Search</span>
          </button>
        </form>
      </div>
    </div>
  )
}
