export const THROTTLED_USER_GROUP_NAME = 'throttled' as const
export const PROTECTED_USER_GROUP_NAMES = ['admin', THROTTLED_USER_GROUP_NAME] as const
export const USER_GROUP_NAMES = ['free' as const, 'support_basic' as const, 'support_plus' as const]
export type IPROTECTED_USER_GROUP_NAMES = typeof PROTECTED_USER_GROUP_NAMES[number]
export type IUSER_GROUP_NAMES = typeof USER_GROUP_NAMES[number]

export const COGNITO_USER_ATTRIBUTES = {
  STRIPE_CUSTOMER_ID: 'custom:stripeCustomerId',
}

export const FEATURE_SUPPORTS: {
  [featureName: string]: {
    [userGroupName in IPROTECTED_USER_GROUP_NAMES | IUSER_GROUP_NAMES]: boolean
  }
} = {
  bookmark: {
    admin: true,
    [THROTTLED_USER_GROUP_NAME]: false,
    free: false,
    support_basic: true,
    support_plus: true,
  },
}
