import { FaustProvider } from '@faustjs/next'
import { AppProps } from 'next/app'
import { FC, PropsWithChildren } from 'react'
import { Authenticator } from '@aws-amplify/ui-react'
import { client } from '../client'
import { GoogleAnalyticsProvider } from './Analytics/GoogleAnalytics'
import { PrivatePageProvider } from './Mypages/Provider'
import { BookmarkProvider } from './Bookmark/Provider'
import { ToastProvider } from './Toast/Provider'

export const AppProvider: FC<
  PropsWithChildren<{
    gaTrackingId?: string
    isProd?: boolean
    pageProps: AppProps['pageProps']
  }>
> = ({ children, isProd = false, gaTrackingId, ...pageProps }) => (
  <ToastProvider>
    <Authenticator.Provider>
      <PrivatePageProvider>
        <BookmarkProvider>
          <GoogleAnalyticsProvider
            gaTrackingId={gaTrackingId}
            debugMode={!isProd}
            defaultConsents={[
              {
                analytics_storage: 'granted',
                ad_storage: 'granted',
                //wait_for_update: 500
              },
              {
                analytics_storage: 'denied',
                ad_storage: 'denied',
                region: [
                  'BE',
                  'BG',
                  'CZ',
                  'DK',
                  'DE',
                  'EE',
                  'IE',
                  'GR',
                  'ES',
                  'FR',
                  'HR',
                  'IT',
                  'CY',
                  'LV',
                  'LT',
                  'LU',
                  'HU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SI',
                  'SK',
                  'FI',
                  'SE',
                ],
              },
            ]}
          >
            <FaustProvider client={client} pageProps={pageProps as any}>
              {children}
            </FaustProvider>
          </GoogleAnalyticsProvider>
        </BookmarkProvider>
      </PrivatePageProvider>
    </Authenticator.Provider>
  </ToastProvider>
)
