import dayjs from 'dayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import useSWR from 'swr'
import { fetcher, getListWPPostURL } from '../../libs/wp/fetchers'
import { IWPPost } from '../../libs/wp/interface'

export const RecentPostsWidget: FC = () => {
  const router = useRouter()
  const { locale } = router || {}
  const { data: posts } = useSWR<IWPPost[]>(getListWPPostURL({ locale, per_page: 5 }), fetcher)
  if (!posts) return null
  return (
    <section aria-labelledby='recent-post-heading'>
      <div className='bg-white rounded-lg shadow'>
        <div className='p-6'>
          <h2 id='recent-post-heading' className='text-base font-medium text-gray-900'>
            Recent Posts
          </h2>
          <div className='mt-6 flow-root'>
            <ul role='list' className='-my-4 divide-y divide-gray-200'>
              {posts.map((post) => (
                <li key={post.id} className='flex py-4 space-x-3'>
                  <div className='min-w-0 flex-1'>
                    <Link href={`/${post.slug}`} passHref>
                      <a>
                        <h3
                          className='text-l font-semibold text-gray-800'
                          dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                        />
                      </a>
                    </Link>
                    <div className='mt-2 flex'>
                      <span className='inline-flex items-center text-sm font-sm text-gray-900'>
                        {dayjs(post.date).format('YYYY/MM/DD')}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
