import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { COGNITO_USER_ATTRIBUTES } from '../../libs/constants'
import { callAPIWithAuth } from '../../libs/AWS/amplify/user.utils'
import { useAuthenticator } from '@aws-amplify/ui-react'

const PrivatePageContext = createContext<{
  userAttributes: CognitoUserAttribute[]
  loadCurrentUserAttributes: () => Promise<void>
  resetUserData: () => void
  stripeCustomerId?: string
  userGroupNames: string[] | undefined
}>({
  userAttributes: [],
  loadCurrentUserAttributes: async () => {},
  resetUserData: () => {},
  userGroupNames: [],
})

export const usePrivatePage = () => useContext(PrivatePageContext)
export const PrivatePageProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [userAttributes, setUserAttributes] = useState<CognitoUserAttribute[]>([])
  const [userGroupNames, setUserGroupNames] = useState<string[] | undefined>(undefined)
  const { user } = useAuthenticator((context) => [context.user])
  const resetUserData = useCallback(() => {
    setUserAttributes([])
    setUserGroupNames(undefined)
  }, [setUserAttributes, setUserGroupNames])
  useEffect(() => {
    if (!user) resetUserData()
  }, [user, resetUserData])
  const loadCurrentUserAttributes = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser().catch(() => null)
    if (!user) {
      setUserAttributes([])
      return
    }
    await Auth.userAttributes(user).then(setUserAttributes)
    await callAPIWithAuth('/api/users/groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((users) => {
        if (Array.isArray(users)) {
          setUserGroupNames(users)
          return
        }
        console.log(users)
      })
      .catch((e) => {
        console.log(e)
        setUserGroupNames([])
      })
  }, [setUserAttributes, setUserGroupNames])
  const didLoadRef = useRef(false)
  useEffect(() => {
    if (didLoadRef.current) return
    didLoadRef.current = true
    loadCurrentUserAttributes()
    return () => {
      setUserAttributes([])
    }
  }, [loadCurrentUserAttributes])

  const stripeCustomerId = useMemo(() => {
    if (!userAttributes || userAttributes.length < 1) return undefined
    const targetAttribute = userAttributes.find(
      (att) => att.Name === COGNITO_USER_ATTRIBUTES.STRIPE_CUSTOMER_ID,
    )
    if (!targetAttribute) return ''
    return targetAttribute.Value || ''
  }, [userAttributes])
  return (
    <PrivatePageContext.Provider
      value={{
        loadCurrentUserAttributes,
        userAttributes,
        stripeCustomerId,
        userGroupNames,
        resetUserData,
      }}
    >
      {children}
    </PrivatePageContext.Provider>
  )
}
