import { FC } from 'react'
import { Disclosure } from '@headlessui/react'
import { useRouter } from 'next/router'
import { menus } from '../../libs/navigations/menus'
import { classNames } from '../../libs/classNames'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { GravatarProfileImage } from '../Gravatar/ProfileImage'
import { getUsername } from '../../libs/AWS/amplify/user.utils'

export const MobileNavigation: FC<{}> = ({}) => {
  const { asPath, push } = useRouter()
  const { user, signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ])
  const buttonClassName =
    'block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 cursor-pointer'
  const username = getUsername(user)
  return (
    <Disclosure.Panel className='lg:hidden'>
      {({ close }: { close: () => void }) => {
        return (
          <>
            <div className='pt-2 pb-3 space-y-1'>
              {menus.header.global.map((globalMenuItem) => {
                const current = asPath === `${globalMenuItem.href}/`.replace(/\/\//, '/')
                const className = classNames(
                  current ? 'bg-indigo-50' : '',
                  'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer',
                )

                return (
                  <Disclosure.Button
                    key={`sm-${globalMenuItem.id}`}
                    as='a'
                    className={className}
                    onClick={async () => {
                      await push(globalMenuItem.href)
                      close()
                    }}
                  >
                    {globalMenuItem.name}
                  </Disclosure.Button>
                )
              })}
            </div>
            <div className='pt-4 pb-3 border-t border-gray-200'>
              {authStatus === 'authenticated' ? (
                <>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      <GravatarProfileImage
                        email={user.attributes?.email}
                        className='h-10 w-10 rounded-full'
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-gray-800'>{username}</div>
                      <div className='text-sm font-medium text-gray-500'>
                        {user.attributes?.email}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 space-y-1'>
                    {menus.mypage.map((mypageMenuItem) => {
                      return (
                        <Disclosure.Button
                          key={`sm-${mypageMenuItem.name}`}
                          as='button'
                          className={buttonClassName}
                          onClick={async () => {
                            await push(mypageMenuItem.href)
                            close()
                          }}
                        >
                          {mypageMenuItem.name}
                        </Disclosure.Button>
                      )
                    })}
                    <Disclosure.Button
                      as='button'
                      className={buttonClassName}
                      onClick={async () => {
                        signOut()
                        await push('/mypage/signin')
                      }}
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </>
              ) : (
                <>
                  <Disclosure.Button
                    as='a'
                    className={buttonClassName}
                    onClick={async () => {
                      await push('/mypage/signin')
                      close()
                    }}
                  >
                    Sign In
                  </Disclosure.Button>
                  <Disclosure.Button
                    as='a'
                    className={buttonClassName}
                    onClick={async () => {
                      await push('/mypage/signup')
                      close()
                    }}
                  >
                    Sign UP
                  </Disclosure.Button>
                </>
              )}
            </div>
          </>
        )
      }}
    </Disclosure.Panel>
  )
}
