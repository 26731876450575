import { InferGetStaticPropsType } from 'next'
import { FC, PropsWithChildren } from 'react'
import { EXPERIMENTALWebShareProvider } from '../../libs/hook/useWebShare'
import { getStaticProps } from '../../pages/[slug]'
import { RecentPostsWidget } from '../Sidebar/RecentPostsWidget'
import { RelatedCategoryPostsWidget } from '../Sidebar/RelatedCategoryPostsWidget'

export const PostLayout: FC<PropsWithChildren<InferGetStaticPropsType<typeof getStaticProps>>> = ({
  children,
  singular,
  relatedPosts,
}) => {
  return (
    <EXPERIMENTALWebShareProvider>
      <div className='py-10'>
        <div className='max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8'>
          <main className='lg:col-span-8'>{children}</main>

          <aside className='hidden lg:block lg:col-span-4'>
            <div className='sticky top-4 space-y-4'>
              {singular && singular.categories ? (
                <RelatedCategoryPostsWidget singular={singular} relatedPosts={relatedPosts} />
              ) : (
                <RecentPostsWidget />
              )}
            </div>
          </aside>
        </div>
      </div>
    </EXPERIMENTALWebShareProvider>
  )
}
