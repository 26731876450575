import { Authenticator } from '@aws-amplify/ui-react'
import { FC, PropsWithChildren } from 'react'
import { signUpAttributes } from '../../libs/AWS/amplify/constants'
import { MypageHeader } from '../Mypages/components/Header'
import { MypageNavigation } from '../Mypages/components/Navigation'
import Head from 'next/head'

export const PrivatePageLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className='py-10'>
      <Head>
        <meta name='robots' content='noindex' />
      </Head>
      <Authenticator signUpAttributes={signUpAttributes}>
        <div className='max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8'>
          <main>
            <MypageHeader />
            <MypageNavigation />
            {children}
          </main>
        </div>
      </Authenticator>
    </div>
  )
}
