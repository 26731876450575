import { IWPCategory, IWPPost } from './interface'

export const getWPLang = (locale: string = 'ja'): WPAPILang => (/en/.test(locale) ? 'en' : 'ja')
export const fetcher = (...args: any) => fetch(args).then((res) => res.json())
export type WPAPILang = 'en' | 'ja'
export type WPListOrder = 'asc' | 'desc'
// post
export type ListWPPostsOption = {
  locale?: string
  page?: number
  per_page?: number
  search?: string
  after?: Date
  before?: Date
  include?: number[]
  exclude?: number[]
  order?: WPListOrder
  orderby?:
    | 'author'
    | 'date'
    | 'id'
    | 'include'
    | 'modified'
    | 'parent'
    | 'relevance'
    | 'slug'
    | 'include_slugs'
    | 'title'
  slug?: string
  categories?: number[]
}
export const getListWPPostURL = (options: ListWPPostsOption = {}) => {
  const query = ['_embed']
  Object.entries(options).map(([key, value]) => {
    switch (key) {
      case 'locale': {
        query.push(`filter[lang]=${getWPLang(value as string)}`)
        break
      }
      case 'after':
      case 'before':
        query.push(`${key}=${(value as Date).getTime()}`)
        break
      default:
        break
    }
    if (Array.isArray(value)) {
      value.forEach((val) => {
        query.push(`${key}[]=${val}`)
      })
      return
    }
    query.push(`${key}=${value}`)
  })
  return `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wp/v2/posts?${query.join('&')}`
}
export type WPAPIError = {
  code: string
  message: string
  data: {
    status: number
  }
}
export type WPAPIResponseBody<Response = any> = Response | WPAPIError
export const isWPAPIError = <Response = any>(
  posts: WPAPIResponseBody<Response>,
): posts is WPAPIError => {
  return (
    Object.prototype.hasOwnProperty.call(posts, 'code') &&
    Object.prototype.hasOwnProperty.call(posts, 'message')
  )
}
export type ListWPPostsResponse = {
  posts: WPAPIResponseBody<IWPPost[]>
  totalPosts: number
  totalPages: number
}
export const listWPPosts = async (options: ListWPPostsOption): Promise<ListWPPostsResponse> => {
  return fetch(getListWPPostURL(options)).then(async (data) => ({
    totalPages: Number(data.headers.get('x-wp-totalpages')),
    totalPosts: Number(data.headers.get('x-wp-total')),
    posts: await data.json(),
  }))
}

//category
export type ListWPCategoriesOption = {
  order?: WPListOrder
  orderby?:
    | 'id'
    | 'include'
    | 'name'
    | 'slug'
    | 'include_slugs'
    | 'term_group'
    | 'description'
    | 'count'
  search?: string
  per_page?: number
  page?: number
  parent?: number
  post?: number
  slug?: string[]
}
export const getListWPCategoryURL = (options: ListWPCategoriesOption = {}) => {
  const query = ['hide_empty=true']
  Object.entries(options).map(([key, value]) => query.push(`${key}=${value}`))

  return `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wp/v2/categories?${query.join('&')}`
}
export const listWPCategories = async (
  options: ListWPCategoriesOption = {},
): Promise<IWPCategory[]> => {
  return fetch(getListWPCategoryURL(options)).then((data) => data.json())
}

export const getRetrieveWPCategoryURL = (id: number) => {
  return `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wp/v2/categories/${id}}`
}
export const retrieveWPCategory = async (id: number): Promise<IWPCategory> => {
  return fetch(getRetrieveWPCategoryURL(id)).then((data) => data.json())
}
