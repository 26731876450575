import Link from 'next/link'
import { FC } from 'react'
import { classNames } from '../../../libs/classNames'

const tabs = [{ name: 'General', href: '/mypage/home', current: true }]

export const MypageNavigation: FC = () => {
  return (
    <div className='px-4 sm:px-6 md:px-0'>
      <div className='py-6 max-w-3xl mx-auto  sm:px-6 lg:max-w-7xl'>
        {/* Tabs */}
        <div className='lg:hidden'>
          <label htmlFor='selected-tab' className='sr-only'>
            Select a tab
          </label>
          <select
            id='selected-tab'
            name='selected-tab'
            className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md'
            defaultValue={tabs.find((tab) => tab.current)?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden lg:block'>
          <div className='border-b border-gray-200'>
            <nav className='-mb-px flex space-x-8'>
              {tabs.map((tab) => (
                <Link key={tab.name} href={tab.href} passHref>
                  <a
                    className={classNames(
                      tab.current
                        ? 'border-purple-500 text-purple-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                    )}
                  >
                    {tab.name}
                  </a>
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
