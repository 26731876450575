import { HomeIcon } from '@heroicons/react/solid'

export const menus = {
  footer: [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Licenses', href: '/licenses' },
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: '特定商取引法に基づく表記', href: '/specified-commercial-transactions-act' },
    { name: '利用規約', href: '/term-of-service' },
    {
      name: 'お問合せ',
      href: 'https://share.hsforms.com/1NmOhfwDFSeKdvvbkz1bv3Q3p3o8',
      external: true,
    },
  ],
  sidebar: [
    {
      name: 'Home',
      href: '/',
      icon: HomeIcon,
    },
    {
      name: 'Last visit',
      href: '/recent-visited',
      icon: HomeIcon,
    },
    {
      name: 'Licenses',
      href: '/licenses',
      icon: HomeIcon,
    },
  ],
  header: {
    global: [
      {
        id: 'home',
        name: 'Home',
        href: '/',
      },
      {
        id: 'about',
        name: 'About',
        href: '/about',
      },
      {
        id: 'bookmark',
        name: 'Bookmark',
        href: '/bookmarks/',
      },
      {
        id: 'mypage',
        name: 'MyPage',
        href: '/mypage/home/',
      },
    ],
  },
  mypage: [
    {
      name: 'MyPage',
      href: '/mypage/home',
    },
  ],
}
