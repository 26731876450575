import Link from 'next/link'
import { FC, ReactNode } from 'react'

export const BottomNavigationItem: FC<{
  label: string
  icon: ReactNode
  href?: string
}> = ({ label, icon, href }) => {
  const content = (
    <>
      <div className='inline-block mb-1'>{icon}</div>
      <span className='tab tab-home block text-xs'>{label}</span>
    </>
  )
  const className =
    'w-full focus:text-indigo-500 hover:text-indigo-500 justify-center inline-block text-center pt-2 pb-1'
  if (!href) {
    return <span className={className}>{content}</span>
  }
  return (
    <Link href={href} passHref>
      <a className={className}>{content}</a>
    </Link>
  )
}
