import { Amplify } from 'aws-amplify'

const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.NEXT_PUBLIC_AMPLIFY_AUTH_IDP_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.NEXT_PUBLIC_AMPLIFY_AUTH_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AMPLIFY_AUTH_UP_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.NEXT_PUBLIC_AMPLIFY_AUTH_UP_CLIENT_ID,
  },
  /*
    Storage: {
      bucket: 'lab.bucket.name',
      region: 'us-east-1',
    },
    */
}
Amplify.configure({ ...amplifyConfig, ssr: true })
