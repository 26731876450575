import 'tailwindcss/tailwind.css'
import '../styles/globals.css'
import 'instantsearch.css/themes/satellite.css'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { Header } from '../components/Headers/Index'
import { Footer } from '../components/Footers/Index'
import '../faust.config'
import { AppLayout } from '../components/Layout/Index'
import { GlobalHead } from '../components/Headers/Head'
import { isProdEnv } from '../libs/env'
import '../libs/AWS/amplify/configure'
import { AppProvider } from '../components/Providers'
import { BottomNavigation } from '../components/BottomNavigation/BottomNavigation'
import { CookieConsentBanner } from '../components/CookieConsent/CookieConsent'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppProvider
      gaTrackingId={process.env.NEXT_PUBLIC_GA_TRACKING_ID}
      isProd={!isProdEnv()}
      pageProps={pageProps}
    >
      <NextNProgress />
      <GlobalHead />
      <Header />
      <AppLayout {...pageProps}>
        <Component {...pageProps} />
      </AppLayout>
      <Footer />
      <BottomNavigation />
      <CookieConsentBanner />
    </AppProvider>
  )
}

export default MyApp
