import { ComponentProps, FC, useMemo } from 'react'
import md5 from 'md5'

export const GravatarProfileImage: FC<
  {
    email?: string
    size?: number
  } & Omit<ComponentProps<'img'>, 'src'>
> = ({ email, size = 64, ...imageProps }) => {
  const imageUrl = useMemo(() => {
    if (email) {
      const hash = md5(email)
      return `https://gravatar.com/avatar/${hash}.jpg?s=${size}`
    }
    return ''
  }, [email, size])

  if (!imageUrl) return null
  return <img alt='' {...imageProps} src={imageUrl} />
}
