import { FC, PropsWithChildren } from 'react'
import { CategoryWidget } from '../Sidebar/CategoryWidget'
import { MenuWidget } from '../Sidebar/MenuWidget'
import { RecentPostsWidget } from '../Sidebar/RecentPostsWidget'

export const ArchiveLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className='py-10'>
      <div className='max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8'>
        <div className='hidden lg:block lg:col-span-3 xl:col-span-2'>
          <nav aria-label='Sidebar' className='sticky top-4 divide-y divide-gray-300'>
            <MenuWidget />
            <CategoryWidget />
          </nav>
        </div>
        <main className='lg:col-span-9 xl:col-span-6'>{children}</main>
        <aside className='hidden xl:block xl:col-span-4'>
          <div className='sticky top-4 space-y-4'>
            <RecentPostsWidget />
          </div>
        </aside>
      </div>
    </div>
  )
}
