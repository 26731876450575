import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { classNames } from '../../libs/classNames'
import { menus } from '../../libs/navigations/menus'

export const GlobalNavigation: FC<{
  siteName: string
  siteLogoURL: string
}> = ({ siteLogoURL, siteName }) => {
  const { asPath } = useRouter()
  return (
    <div className='flex px-2 lg:px-0'>
      <div className='flex-shrink-0 flex items-center'>
        <Link href='/' passHref>
          <a className='flex items-center'>
            <img className='block h-8 w-auto mr-2' src={siteLogoURL} alt='' />
            <h1 className='font-bold text-lg hidden md:block'>{siteName}</h1>
          </a>
        </Link>
      </div>
      <div className='hidden lg:ml-6 lg:flex lg:space-x-8'>
        {menus.header.global.map((globalMenuItem) => {
          const current = asPath === `${globalMenuItem.href}/`.replace(/\/\//, '/')
          const className = classNames(
            current
              ? 'border-indigo-500 text-gray-900'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
          )
          return (
            <Link key={`pc-${globalMenuItem.id}`} href={globalMenuItem.href} passHref>
              <a className={className}>{globalMenuItem.name}</a>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
