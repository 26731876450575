import { BookmarkIcon, HomeIcon, SearchIcon, UserCircleIcon } from '@heroicons/react/solid'
import { FC } from 'react'
import { BottomNavigationItem } from './BottomNavigationItem'

export const BottomNavigation: FC = () => {
  return (
    <section
      id='bottom-navigation'
      className='block md:hidden fixed inset-x-0 bottom-0 z-10 bg-white shadow z-40'
    >
      <div id='tabs' className='flex justify-between'>
        <BottomNavigationItem label='Home' icon={<HomeIcon width={25} height={25} />} href='/' />
        <BottomNavigationItem
          label='Search'
          icon={<SearchIcon width={25} height={25} />}
          href='/search/'
        />
        <BottomNavigationItem
          label='Bookmark'
          icon={<BookmarkIcon width={25} height={25} />}
          href='/bookmarks/'
        />
        <BottomNavigationItem
          label='Mypage'
          icon={<UserCircleIcon width={25} height={25} />}
          href='/mypage/home/'
        />
      </div>
    </section>
  )
}
