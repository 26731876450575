import Head from 'next/head'
import Script from 'next/script'
import { ComponentProps, FC } from 'react'
import { client } from '../../client'

export const GlobalHead: FC<{
  siteName?: string | null
}> = () => {
  const { useQuery } = client
  const query = useQuery()
  const siteName = query.generalSettings?.title || 'WP Kyoto'
  const description =
    query.generalSettings?.description ||
    'React / AWS / WordPress / Stripe / Algoliaなどに関する開発Tips系ブログ'
  const headerInfo = {
    title: siteName,
    links: [
      {
        rel: 'stylesheet',
        href: 'https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '192x192',
        href: '/images/icon-192.png',
      },
      //{ rel: "manifest", href: "/manifest.json" },
      { rel: 'shortcut icon', href: '/images/favicon.ico' },
    ],
    meta: [
      { name: 'application-name', content: siteName },
      { name: 'pple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
      { name: 'apple-mobile-web-app-title', content: siteName },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'theme-color', content: '#f5f5f5' },
      {
        name: 'viewport',
        content:
          'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover',
      },
      { property: 'og:image', content: process.env.NEXT_PUBLIC_OGP_DEFAULT_IMAGE_URL },
      { name: 'description', content: description },
    ],
  }
  return (
    <>
      <Head>
        <title>{headerInfo.title}</title>
        {headerInfo.meta.map((meta) => {
          const props: ComponentProps<'meta'> = {}
          if (meta.name) props.name = meta.name
          if (meta.property) props.property = meta.property
          if (meta.content) props.content = meta.content
          if (Object.keys(props).length < 2) return null
          return <meta {...props} key={meta.name || meta.property} />
        })}
        {headerInfo.links.map((link) => {
          const linkProps: ComponentProps<'link'> = {}
          if (link.href) linkProps.href = link.href
          if (link.rel) linkProps.rel = link.rel
          if (link.sizes) linkProps.sizes = link.sizes
          if (Object.keys(link).length < 2) return null
          return <link {...linkProps} key={link.rel} />
        })}
      </Head>
      <Script
        id='hatena-bookmark'
        type='text/javascript'
        src='//b.st-hatena.com/js/bookmark_button.js'
        async
      />
    </>
  )
}
