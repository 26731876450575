import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'
import { classNames } from '../../libs/classNames'
import { ArchiveLayout } from './Archive'

const tabs = [
  { name: 'Timeline', href: '/' },
  { name: 'Recent Visited', href: '/recent-visited/' },
  //{ name: 'Most Answers', href: '#' },
]

export const HomeLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { asPath, push } = useRouter()
  const currentPath = asPath.replace(/\/\//, '/')
  return (
    <ArchiveLayout>
      <div className='px-4 sm:px-0'>
        <div className='sm:hidden'>
          <label htmlFor='question-tabs' className='sr-only'>
            Select a tab
          </label>
          <select
            id='question-tabs'
            className='block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-rose-500 focus:ring-rose-500'
            onChange={(event) => {
              const href = event.target.value
              if (href !== currentPath) {
                push(href)
              }
            }}
            value={tabs.find((tab) => tab.href === currentPath)?.href}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <nav
            className='relative z-0 rounded-lg shadow flex divide-x divide-gray-200'
            aria-label='Tabs'
          >
            {tabs.map((tab, tabIdx) => {
              const current = tab.href === currentPath
              return (
                <Link key={tab.name} href={tab.href} passHref>
                  <a
                    aria-current={current ? 'page' : undefined}
                    className={classNames(
                      current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                    )}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden='true'
                      className={classNames(
                        current ? 'bg-rose-500' : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5',
                      )}
                    />
                  </a>
                </Link>
              )
            })}
          </nav>
        </div>
      </div>
      {children}
    </ArchiveLayout>
  )
}
