import { FEATURE_SUPPORTS } from '../constants'

export const canUseBookmarkFeature = (userGroupNames: string[]): boolean => {
  let permitted = false
  userGroupNames.some((groupName) => {
    const groupPermission = (FEATURE_SUPPORTS.bookmark as any)[groupName] as boolean | undefined
    if (groupPermission === undefined) return false
    if (groupPermission === true) {
      permitted = true
      return false
    } else {
      permitted = false
      return true
    }
  })
  return permitted
}
