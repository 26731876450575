import { FC } from 'react'
import Link from 'next/link'
import useSWR from 'swr'
import { fetcher, getListWPCategoryURL, getWPLang } from '../../libs/wp/fetchers'
import { IWPCategory } from '../../libs/wp/interface'
import { useRouter } from 'next/router'

export const CategoryWidget: FC = () => {
  const { locale } = useRouter()
  const { data: categories } = useSWR<IWPCategory[]>(
    getListWPCategoryURL({
      orderby: 'count',
      order: 'desc',
    }),
    fetcher,
  )

  return (
    <>
      {categories ? (
        <div className='pt-10'>
          <p
            className='px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider'
            id='categories-headline'
          >
            Categories
          </p>
          <div className='mt-3 space-y-2' aria-labelledby='categories-headline'>
            {categories.map((category, i) => {
              return (
                <Link key={`${category.id}-${i}`} href={`/category/${category.slug}`} passHref>
                  <a className='group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50'>
                    <span className='truncate'>
                      {`${category.name}${
                        getWPLang(locale) === 'ja' ? ` (${category.count})` : ''
                      }`}
                    </span>
                  </a>
                </Link>
              )
            })}
          </div>
        </div>
      ) : null}
    </>
  )
}
