import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { ListUserGroupAPIResponse } from '../../../pages/api/users/groups'

type UseAuthenticatorResult = ReturnType<typeof useAuthenticator>
type CognitoUserAmplify = UseAuthenticatorResult['user']

export const getUsername = (user?: CognitoUserAmplify): string => {
  if (!user) return ''
  const { username, attributes } = user
  if (attributes) {
    const preferredUsername = attributes['preferred_username']
    if (preferredUsername) return preferredUsername
  }
  return username || ''
}

export const callAPIWithAuth = async (
  input: RequestInfo,
  init: RequestInit = {},
): Promise<Response> => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()
  if (!init.headers) init.headers = {}
  const result = await fetch(input, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: token,
    },
  })
  return result
}
