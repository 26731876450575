import Link from 'next/link'
import { FC } from 'react'
import { menus } from '../../libs/navigations/menus'
import { socialMenus } from '../../libs/navigations/social'

export const Footer: FC = () => {
  return (
    <footer className='bg-white'>
      <div className='max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8'>
        <nav className='-mx-5 -my-2 flex flex-wrap justify-center' aria-label='Footer'>
          {menus.footer.map((item) => (
            <div key={item.name} className='px-5 py-2'>
              {item.external ? (
                <a
                  href={item.href}
                  target='_blank'
                  rel='noopenner noreferrer'
                  className='text-base text-gray-500 hover:text-gray-900'
                >
                  {item.name}
                </a>
              ) : (
                <Link href={item.href} passHref>
                  <a className='text-base text-gray-500 hover:text-gray-900'>{item.name}</a>
                </Link>
              )}
            </div>
          ))}
        </nav>
        <div className='mt-8 flex justify-center space-x-6'>
          {socialMenus.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className='text-gray-400 hover:text-gray-500'
              target='_blank'
              rel='noopenner noreferrer'
            >
              <span className='sr-only'>{item.name}</span>
              <item.icon className='h-6 w-6' aria-hidden='true' />
            </a>
          ))}
        </div>
        <p className='mt-8 text-center text-base text-gray-400'>
          &copy; 2022 Hidetaka Okamoto. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
