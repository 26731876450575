import { Share, ShareOptions } from '@capacitor/share'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const WebShareContext = createContext<{
  polifilLoaded: boolean
  canShare: boolean
}>({
  polifilLoaded: false,
  canShare: false,
})

export const useWebShareEXPERIMENTAL = () => {
  const context = useContext(WebShareContext)
  if (!context) {
    throw new Error('Web Share component can not be use outside the WebShareProvider component')
  }
  return {
    ...context,
    share: (option: ShareOptions) => {
      return async () => {
        if (!context.polifilLoaded && (!window || !window.navigator)) {
          throw new Error('Share API not available in this browser')
        }
        await Share.share(option)
      }
    },
  }
}

export const EXPERIMENTALWebShareProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [polifilLoaded, setPolifilLoaded] = useState(false)
  const [canShare, setCanShare] = useState(false)
  const didLoaded = useRef(false)

  useEffect(() => {
    if (didLoaded.current === true) return
    didLoaded.current = true
    Share.canShare().then(({ value }) => {
      if (value !== true) return
      setCanShare(true)
      try {
        require('share-api-polyfill')
        setPolifilLoaded(true)
      } catch (e) {
        setPolifilLoaded(false)
      }
    })
  }, [setPolifilLoaded])

  return (
    <WebShareContext.Provider
      value={{
        polifilLoaded,
        canShare,
      }}
    >
      {children}
    </WebShareContext.Provider>
  )
}
