import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { Toast } from './Toast'

export type ToastMessage = {
  id: string
  type: 'success' | 'error'
  title: string
  message?: string
}
const ToastContext = createContext<{
  putToastMessages: (...messages: Omit<ToastMessage, 'id'>[]) => void
  removeToastMessage: (messageId: string) => void
}>({} as any)

export const useToast = () => useContext(ToastContext)

export const ToastProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])
  const putToastMessages = useCallback(
    (...items: Omit<ToastMessage, 'id'>[]) => {
      const newItems = Array.from(new Set([...messages, ...items])).map((d, i) => ({
        ...d,
        id: `toast-${i}`,
      }))
      setMessages(newItems)
    },
    [setMessages, messages],
  )
  const removeToastMessage = useCallback(
    (messageId: string) => {
      const remainItems = messages.filter((message) => message.id !== messageId)
      setMessages(remainItems)
    },
    [messages, setMessages],
  )
  return (
    <ToastContext.Provider
      value={{
        putToastMessages,
        removeToastMessage,
      }}
    >
      <>
        {children}
        {messages ? (
          <div
            aria-live='assertive'
            className='fixed inset-0 flex items-start px-4 py-6 pointer-events-none sm:p-6'
          >
            <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
              {messages.map((message) => (
                <Toast key={message.id} {...message} />
              ))}
            </div>
          </div>
        ) : null}
      </>
    </ToastContext.Provider>
  )
}
